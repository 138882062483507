<template>
  <div class="hk-content">
    <div 
      class="hk-search"
    >
      <h4>{{ $t('search.title') }}</h4>
      <ul class="hk-search__list">
        <li
          v-for="(item, index) of getSearchResult"
          :key="index"
          class="hk-search__item"
          @click="goDetail(item.id)"
        >
            <h4>{{ item.category_name }} - {{ item.product_name}}</h4>
            <p v-for="desc of item.introduce">
              <span>{{ desc }}</span>
            </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkSearch',
  data() {
    return {
      result : [],
      keyword: '',
    } 
  },
  computed: {
    data () {
      return this.result
    }
  },
  methods: {
    goDetail (id) {
      this.$router.push({
        name: 'detail',
        query: { id }
      })
    },
    getResult () {

    }
  },
  watch: {
    getSearchValue (val) {
      if (this.keyword == '') {
        let result = this.$store.state.config.search;
        this.keyword = result.keyword;
      }
    },
  },
  computed: {
    getSearchResult () {
      let array = this.$store.state.config.search.result;
      return array
    },
  }
}
</script>

<style lang="scss" scoped>
@import './styles/search';
</style>
